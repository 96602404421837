import React from "react"
import { Link } from "gatsby"

const ProjectLink = ({ project }) => (
  <div>
    <h3>{project.frontmatter.title}</h3>
    <p>{project.excerpt}</p>
    <p><strong>{project.frontmatter.institution}</strong></p>
    <Link to={'/projects' + project.frontmatter.slug}>
      &gt;&gt;&gt; Mehr
    </Link>
  </div>
)

export default ProjectLink 