import React from "react"
import { graphql } from "gatsby"
import ProjectLink from "../components/project-link"
import Layout from "../components/layout"

const ProjectPage = ({
  data: {
    allMarkdownRemark: { edges },
  },
}) => {
  const Projects = edges.map(edge => (
    <ProjectLink key={edge.node.id} project={edge.node} />
  ))

  return (
    <Layout>
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8 lg:py-24">
      <h1>
        <span className="block text-base text-center text-tefaf font-semibold tracking-wide uppercase">
          KPF.NRW
        </span>
        <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
          Projekte
        </span>
      </h1>
      <div className="mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto">
        <div>{Projects}</div>
      </div>
      </div>
    </Layout>
  )
}

export default ProjectPage

export const pageQuery = graphql`
  query {
    allMarkdownRemark( filter: { frontmatter: { type: { eq: "project" } } },
      sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            date(formatString: "DD. MMMM YYYY", locale: "de-DE")
            slug
            title
            institution
          }
        }
      }
    }
  }
`
